import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from 'react-query';
import { queryTaxonomy } from 'libs/taxonomy';

import PageView from 'components/Pages/PageView';
import Footer from 'components/Footer';
import { PageContext } from 'context/page';
import { CustomerContext } from 'context/customer';
import { LinearProgress } from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import NotFound from 'components/NotFound';

import {FeedbackQuestion} from 'common/types';
import {feedbackQuestionTypes} from 'common/constants';

import {addPageFeedback} from 'sideEffects/api';

import { trackFeedback } from 'libs/analytics';


// Feedback functions

function calcFeedbackProgress(
  questionIndex: number,
  feedbackQuestionsLength: number
) {
  return (questionIndex / feedbackQuestionsLength) * 100 || 2.5;
}

function getProgressText(
  questionIndex: number,
  t: any,
  feedbackQuestionsLength: number
) {
  if (questionIndex >= feedbackQuestionsLength) return '';
  return t('pages.view.feedback.progressText', {
    questionsIndex: questionIndex + 1,
    questionsLength: feedbackQuestionsLength,
  });
}

function getProgressTextAriaLabel(
  questionIndex: number,
  t: any,
  feedbackQuestionsLength: number
) {
  if (questionIndex >= feedbackQuestionsLength) return '';
  return t('pages.view.feedback.progressTextAriaLabel', {
    questionsIndex: questionIndex + 1,
    questionsLength: feedbackQuestionsLength,
  });
}

function Page() {
  const [page, pageActions] = useContext(PageContext);
  const [customer, customerActions] = useContext(CustomerContext);
  const { pageId } = useParams();
  const { t } = useTranslation();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);

  

  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  const taxonomyQuery = useQuery(
    ['apiTaxonomy', { language }],
    () => queryTaxonomy(language),
    {
      // eslint-disable-next-line no-console
      onError: (e) => console.error(e),
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    pageActions.onRequest(pageId, language);
  }, [pageId, language]);

  useEffect(() => {
    if (customer.data) return;

    customerActions.onRequest();
  }, []);

  if (taxonomyQuery.isError) {
    return <NotFound />;
  }

  if (page.didInvalidate) {
    return <NotFound message={page.error.message} code={page.error.code} />;
  }

  if (
    !page.data ||
    page.isFetching ||
    customer.isFetching ||
    !customer.data ||
    taxonomyQuery.isLoading
  ) {
    return <LinearProgress color="primary" />;
  }

  // Feedback implementation
  const FEEDBACK_QUESTIONS: FeedbackQuestion[] = [
    {
      text: t('pages.view.feedback.questions', {
        returnObjects: true,
      })[0],
      type: feedbackQuestionTypes.EMOJI,
    },
    {
      text: t('pages.view.feedback.questions', {
        returnObjects: true,
      })[1],
      type: feedbackQuestionTypes.EMOJI,
    },
    {
      text: t('pages.view.feedback.questions', {
        returnObjects: true,
      })[2],
      type: feedbackQuestionTypes.EMOJI,
    },
    {
      text: t('pages.view.feedback.questions', {
        returnObjects: true,
      })[3],
      type: feedbackQuestionTypes.THANKS,
    },
  ];
  const feedbackQuestionsLength = FEEDBACK_QUESTIONS.length - 1;

  const onResponse = (type: string, answer: string) => {
    setQuestionIndex(questionIndex + 1);
    setAnswers(answers.concat(answer));
    addPageFeedback({
      page_id: page.data?.id,
      value: answer,
      question: FEEDBACK_QUESTIONS[questionIndex]?.text,
    }, pageId);
    trackFeedback({
      response: answer,
      question: FEEDBACK_QUESTIONS[questionIndex]?.text,
      elementType: 'page',
      elementId: pageId,
      elementName: page.data?.name,
    });
  };

  return (
    <>
      <PageHeader pageHeader={page.data?.pageHeader} />
      <PageView
        page={page.data}
        dtprTaxonomy={taxonomyQuery.data}
        onResponse={(answer: string) => {
          onResponse(FEEDBACK_QUESTIONS[questionIndex].type, answer);
        }}
        question={FEEDBACK_QUESTIONS[questionIndex]}
        progressText={getProgressText(
          questionIndex,
          t,
          feedbackQuestionsLength
        )}
        progressTextAriaLabel={getProgressTextAriaLabel(
          questionIndex,
          t,
          feedbackQuestionsLength
        )}
        progressValue={calcFeedbackProgress(
          questionIndex,
          feedbackQuestionsLength
        )}
      />
      <Footer customer={customer.data} />
    </>
  );
}

export default Page;
