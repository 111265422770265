import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from 'react-query';
import { queryTaxonomy } from 'libs/taxonomy';

import { getSensor, addSensorFeedback, redirectIfFirestoreId } from 'sideEffects/api';
import sensorReducer, {
  sensorInitialState,
  fetchSensorRequested,
  fetchSensorSuccessed,
  fetchSensorFailed,
} from 'reducers/sensor';

import useReducerState from 'hooks/useReducerState';
import { CustomerContext } from 'context/customer';
import SensorView from 'components/Sensors/SensorView';
import Footer from 'components/Footer';
import PageHeader from 'components/PageHeader';
import { feedbackQuestionTypes } from 'common/constants';
import { LinearProgress } from 'libs/mui';
import { prepareSensorTaxonomy } from 'presenters/sensor';
import {
  FAQ,
  FeedbackQuestion,
  TaxonomyPropLabels,
} from 'common/types';
import NotFound from 'components/NotFound';
import { trackFeedback } from 'libs/analytics';

function calcFeedbackProgress(
  questionIndex: number,
  feedbackQuestionsLength: number
) {
  return (questionIndex / feedbackQuestionsLength) * 100 || 2.5;
}

function getProgressText(
  questionIndex: number,
  t: any,
  feedbackQuestionsLength: number
) {
  if (questionIndex >= feedbackQuestionsLength) return '';
  return t('sensors.view.feedback.progressText', {
    questionsIndex: questionIndex + 1,
    questionsLength: feedbackQuestionsLength,
  });
}

function getProgressTextAriaLabel(
  questionIndex: number,
  t: any,
  feedbackQuestionsLength: number
) {
  if (questionIndex >= feedbackQuestionsLength) return '';
  return t('sensors.view.feedback.progressTextAriaLabel', {
    questionsIndex: questionIndex + 1,
    questionsLength: feedbackQuestionsLength,
  });
}

function Sensor() {
  const { sensorId, pageId } = useParams();
  const { t } = useTranslation();
  const [sensor, sensorActions] = useReducerState(
    sensorReducer,
    sensorInitialState,
    fetchSensorRequested,
    fetchSensorSuccessed,
    fetchSensorFailed
  );
  const [customer, customerActions] = useContext(CustomerContext);

  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);

  const taxonomyPropLabels: TaxonomyPropLabels = t('taxonomy.labels', {
    returnObjects: true,
  });
  const FEEDBACK_QUESTIONS: FeedbackQuestion[] = [
    {
      text: t('sensors.view.feedback.questions', {
        returnObjects: true,
      })[0],
      type: feedbackQuestionTypes.EMOJI,
    },
    {
      text: t('sensors.view.feedback.questions', {
        returnObjects: true,
      })[1],
      type: feedbackQuestionTypes.EMOJI,
    },
    {
      text: t('sensors.view.feedback.questions', {
        returnObjects: true,
      })[2],
      type: feedbackQuestionTypes.EMOJI,
    },
    {
      text: t('sensors.view.feedback.questions', {
        returnObjects: true,
      })[3],
      type: feedbackQuestionTypes.EMOJI,
    },
    {
      text: t('sensors.view.feedback.questions', {
        returnObjects: true,
      })[4],
      type: feedbackQuestionTypes.EMOJI,
    },
    {
      text: t('sensors.view.feedback.questions', {
        returnObjects: true,
      })[5],
      type: feedbackQuestionTypes.THANKS,
    },
  ];

  const feedbackQuestionsLength = FEEDBACK_QUESTIONS.length - 1;

  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  const taxonomyQuery = useQuery(
    ['apiTaxonomy', { language }],
    () => queryTaxonomy(language),
    {
      // eslint-disable-next-line no-console
      onError: (e) => console.error(e),
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    async function fetchData() {
      await redirectIfFirestoreId('sensors', sensorId);
      
      sensorActions.onRequest();
      getSensor(sensorId, language, sensorActions.onSuccess, sensorActions.onError);
    }

    fetchData();
  }, [sensorId, language]);


  useEffect(() => {
    if (customer.data) return;

    customerActions.onRequest();
  }, []);

  const sensorTaxonomy = useMemo(() => {
    if (!sensor.data) return {};
    return prepareSensorTaxonomy({
      taxonomyApiData: taxonomyQuery.data,
      datachain: sensor.data?.datachain,
      taxonomyPropLabels,
    });
  }, [sensor.data, taxonomyPropLabels, taxonomyQuery.isSuccess]);

  const faq = useMemo(() => {
    if (!sensor.data?.FAQ) return [];
    return Object.values(sensor.data.FAQ) as FAQ[];
  }, [sensor.data]);

  const onResponse = (type: string, answer: string) => {
    setQuestionIndex(questionIndex + 1);
    setAnswers(answers.concat(answer));
    addSensorFeedback({
      page_id: sensor.data?.pages?.[0]?.id,
      value: answer,
      question: FEEDBACK_QUESTIONS[questionIndex]?.text,
    }, sensorId);
    trackFeedback({
      response: answer,
      question: FEEDBACK_QUESTIONS[questionIndex]?.text,
      elementType: 'sensor',
      elementId: sensorId,
      elementName: sensor.data?.name,
    });
  };
  if (taxonomyQuery.isError) {
    return <NotFound />;
  }

  if (sensor.didInvalidate) {
    return <NotFound message={sensor.error.message} code={sensor.error.code} />;
  }

  if (
    !sensor.data ||
    sensor.isFetching ||
    taxonomyQuery.isLoading ||
    customer.isFetching ||
    !customer.data
  ) {
    return <LinearProgress color="primary" />;
  }

  return (
    <>
      <PageHeader pageHeader={sensor.data?.pageHeader} />
      <SensorView
        sensor={sensor.data}
        sensorId={sensorId}
        pageId={pageId}
        sensorTaxonomy={sensorTaxonomy}
        dtprTaxonomy={taxonomyQuery.data}
        faq={faq}
        onResponse={(answer: string) => {
          onResponse(FEEDBACK_QUESTIONS[questionIndex].type, answer);
        }}
        question={FEEDBACK_QUESTIONS[questionIndex]}
        progressText={getProgressText(
          questionIndex,
          t,
          feedbackQuestionsLength
        )}
        progressTextAriaLabel={getProgressTextAriaLabel(
          questionIndex,
          t,
          feedbackQuestionsLength
        )}
        progressValue={calcFeedbackProgress(
          questionIndex,
          feedbackQuestionsLength
        )}
      />
      <Footer customer={customer.data} />
    </>
  );
}

export default Sensor;
