/* eslint-disable import/no-extraneous-dependencies */
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import apiAxios from 'libs/apiAxios';

import {
  FeedbackResponse,
  PageData,
  SensorData,
  CustomerData,
} from '../common/types';

// Get default page to which to redirect
export async function getDefaultPage() {
  try {
    const { data } = await apiAxios.get('/pages/default');

    return data.id;
  } catch (e) {
    throw Error(e);
  }
}

function trackFirestoreVisit() {
  if (typeof window.plausible === 'undefined') {
    return;
  }
  window.plausible('pageview');
}

export async function redirectIfFirestoreId(table: string, id: string) {
  const { data } = await apiAxios.get(`${table}/${id}/firestore_check`);

  if (data?.type === 'firestore') {
    trackFirestoreVisit();
    window.location.href = `/${table}/${data.id}`;
  }
}

function mapSensor(s: any) {
  const sensor = {
    ...s,
    FAQ: s.questions,
    datachain: groupBy(
      sortBy(s.datachain, ({ priority }) => {
        const number = Number.parseInt(priority, 10);
        if (Number.isNaN(number)) return null;
        return number;
      }),
      ({ category }) => category
    ),
  };

  delete sensor.questions;

  return sensor;
}

export async function getDefaultPageAction(
  onSuccess: (arg0: { id: any }) => void,
  onError: (arg0: { code: number; status: string; message: string }) => void
) {
  try {
    const { data } = await apiAxios.get('/pages/default');

    const pageData = data as PageData;

    if (!pageData) {
      onError({
        code: 404,
        status: 'Not Found',
        message: 'Page not found',
      });
      return;
    }
    onSuccess(pageData);
  } catch (e) {
    onError(e);
  }
}

// Gets customer information object
export async function getCustomer(
  onSuccess: (arg0: { id: any }) => void,
  onError: (arg0: { code: number; status: string; message: string }) => void
) {
  try {
    const { data } = await apiAxios.get(`/customer`);

    const customerData = data as CustomerData;

    if (!customerData) {
      onError({
        code: 404,
        status: 'Not Found',
        message: 'Customer not found',
      });
      return;
    }
    onSuccess(customerData);
  } catch (e) {
    onError(e);
  }
}

// Gets a single page object
export async function getPage(
  pageId: string,
  locale: string,
  onSuccess: (arg0: { id: any }) => void,
  onError: (arg0: { code: number; status: string; message: string }) => void
) {
  try {
    const { data } = await apiAxios.get(`/pages/${pageId}?locale=${locale}`);

    const pageData = data as PageData;

    if (!pageData) {
      onError({
        code: 404,
        status: 'Not Found',
        message: 'Page not found',
      });
      return;
    }
    onSuccess(pageData);
  } catch (e) {
    onError(e);
  }
}

// Gets a single sensor object
export async function getSensor(
  sensorId: string,
  locale: string,
  onSuccess: (param?: SensorData) => void,
  onError: (e: any) => void
) {
  try {
    const { data } = await apiAxios.get(`/technologies/${sensorId}?locale=${locale}`);

    const sensor = mapSensor(data) as SensorData;

    if (!sensor) {
      onError({
        code: 404,
        status: 'Not Found',
        message: 'Sensor not found',
      });
      return;
    }

    onSuccess(sensor);
  } catch (e) {
    onError(e);
  }
}

// Gets all sensors in a page
export async function getSensorsByPage(
  page: PageData,
  locale: string,
  onSuccess: (param: { [sensorId: string]: SensorData }) => void,
  onError: (e: any) => void
) {
  try {
    const sensors: { [sensorId: string]: SensorData } = {};

    const { data } = await apiAxios.get(`/pages/${page.id}/technologies?locale=${locale}`);

    data.forEach((s) => {
      sensors[s.id] = mapSensor(s);
    });

    onSuccess(sensors);
  } catch (e) {
    onError(e);
  }
}

export async function addSensorFeedback(feedback: FeedbackResponse, sensorId: string) {
  try {
    await apiAxios.post(`/technologies/${sensorId}/feedback`, {
      feedback,
    });
  } catch (e) {
    throw Error(e);
  }
}

export async function addPageFeedback(feedback: FeedbackResponse, pageId: string){
  try{
    await apiAxios.post(`/pages/${pageId}/feedback`,{
      feedback,
    });
  } 
  catch(e){
      throw Error(e);
  }
}
