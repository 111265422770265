import axios from 'axios';

const apiAxios = axios.create({
  baseURL: `${process.env.REACT_APP_HP_API_HOST}/api/guide_app`,
  params: {
    customer_id: process.env.REACT_APP_HP_CUSTOMER_ID,
  },
});

export default apiAxios;
